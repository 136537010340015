.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.squares {
  animation: move-left-right 1s infinite;
  background: #3358f4;
  background: -webkit-linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
  background: -o-linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
  background: -moz-linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
  background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
  position: absolute;
  transition: 0.5s ease-out;
  overflow: hidden;
  border-radius: 20%;
}

.squares.square1 {
  animation: move-left-right 4s infinite;
  height: 300px;
  width: 300px;
  opacity: 0.5;
  left: 3%;
  top: -21%;
}

.squares.square2 {
  animation: move-left-right 6s infinite;
  height: 400px;
  width: 400px;
  opacity: 0.4;
  right: -5%;
  top: -12%;
}

.squares.square3 {
  animation: move-left-right 5s infinite;
  height: 200px;
  width: 200px;
  opacity: 0.1;
  left: -5%;
  bottom: 0%;
}

.squares.square4 {
  animation: move-left-right 10s infinite;
  height: 100px;
  width: 100px;
  opacity: 0.9;
  right: 27%;
  top: 70%;
}

.squares.square5 {
  animation: move-left-right 6s infinite;
  height: 250px;
  width: 250px;
  opacity: 0.1;
  left: 32%;
  bottom: 29%;
}

.squares.square6 {
  animation: move-left-right 9s infinite;
  left: 10%;
  top: 35%;
  height: 80px;
  width: 80px;
  opacity: 0.8;
}

.squares.square7 {
  animation: move-left-right 3s infinite;
  width: 300px;
  height: 300px;
  right: -5%;
  bottom: 0%;
  opacity: 0.1;
}

.slide-in {
  animation: slideIn 0.3s forwards;
}

.slide-out {
  animation: slideOut 0.3s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}